// -----------------------------------------------------------------------------
// MIXINS
// -----------------------------------------------------------------------------

/// Responsive
@mixin mobile {
	@media (max-width: 767px) {
		@content;
	}
}

@mixin mobile-min {
	@media (max-width: 359px) {
		@content;
	}
}

@mixin tablet {
	@media (min-width: 768px) { 
		@content;
	}
}

@mixin desktop-min {
	@media (min-width: 1025px) {
		@content;
	}
}

@mixin desktop {
	@media (min-width: 1360px) {
		@content;
	}
}

@mixin desktop-max {
	@media (min-width: 1820px) {
		@content;
	}
}

@mixin vw($property, $size) {
  @media (max-width: 767px) {
		#{$property}: get-vw($size, 720);
  }
	@media (min-width: 768px) {
		#{$property}: get-vw($size, 768);
	}
	@media (min-width: 1025px) {
		#{$property}: get-vw($size, 1920);
	}
}

/// Event wrapper
@mixin on-event($self: false) {
	@if $self {
		&,
		&:hover,
		&:active,
		&:focus {
			@content;
		}
	} @else {
		&:hover,
		&:active,
		&:focus {
			@content;
		}
	}
}

/// image
@mixin background($x: 0, $y: 0, $url: null, $repeat: no-repeat, $color: null, $background-size: 300px) {
	@if $url {
		background-image: url($url);
	}

	display: inline-block;
	background-position: $x $y;
	background-repeat: $repeat;
	background-color: $color;
	background-size:  $background-size;
}

@mixin background-icon($name: null, $x: 0, $y: 0, $width: 30px, $height: 30px, $margin: null, $icon-width: 30px, $icon-height: 30px, $url: $image-url-icon, $background-size: 300px) {
	.icon-#{$name} {
		position: relative;
		display: inline-block;
		margin: $margin;
		width: $width;
		height: $height;
		text-align: left;
		text-indent: -9999px;
		vertical-align: top;

		&::after {
			@include background($x, $y, $url, $background-size: $background-size);
			@include position-center;

			content: '';
			width: $icon-width;
			height: $icon-height;
		}
	}
}

@mixin before-icon($x: 0, $y: 0, $width: 30px, $height: 30px, $background-size: 300px, $margin: null) {
	&::before {
		@include position-center;
		@include background($x, $y, $image-url-icon, $background-size: $background-size);

		content: '';
		margin: $margin;
		width: $width;
		height: $height;
	}
}

@mixin after-icon($x: 0, $y: 0, $width: 30px, $height: 30px, $background-size: 300px, $margin: null) {
	&::after {
		@include position-center;
		@include background($x, $y, $image-url-icon, $background-size: $background-size);

		content: '';
		margin: $margin;
		width: $width;
		height: $height;
	}
}

@mixin before-btn($x: 0, $y: 0, $width: 30px, $height: 30px, $background-size: 300px, $margin: null) {
	&::before {
		@include position-center;
		@include background($x, $y, $image-url-btn, $background-size: $background-size);

		content: '';
		margin: $margin;
		width: $width;
		height: $height;
	}
}

@mixin after-btn($x: 0, $y: 0, $width: 30px, $height: 30px, $background-size: 300px, $margin: null) {
	&::after {
		@include position-center;
		@include background($x, $y, $image-url-btn, $background-size: $background-size);

		content: '';
		margin: $margin;
		width: $width;
		height: $height;
	}
}

/// dot
@mixin dot($width: $size-dot, $height: $size-dot, $color: $color-text1, $margin: null) {
	&::before {
		@include position(0, 0);

		content: '';
		margin: $margin;
		width: $width;
		height: $height;
		border-radius: $width;
		background-color: $color;
	}
}

@mixin before-line($width: 1px, $height: 13px, $margin: null, $color: $color-bg2) {
	&::before {
		@include position(0, 0);

		content: '';
		margin: $margin;
		width: $width;
		height: $height;
		background-color: $color;
		vertical-align: top;
	}
}

@mixin after-line($width: 1px, $height: 13px, $margin: null, $color: $color-bg2) {
	&::after {
		@include position(0, auto, 0);

		content: '';
		margin: $margin;
		width: $width;
		height: $height;
		background-color: $color;
		vertical-align: top;
	}
}

/// clear
@mixin clear {
	&:after {
		clear: both;
		content: '';
		display: table;
	}
}

/// ellipsis
@mixin ellipsis($lines: null) {
	@if ($lines==null) {
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}

	@else {
		display: block;
		display: -webkit-box;
		overflow: hidden;
		text-overflow: ellipsis;
		-webkit-line-clamp: $lines;
		-webkit-box-orient: vertical;
	}
}

/// position
@mixin position($top: null, $left: null, $right: null, $bottom: null) {
	position: absolute;
	top: $top;
	left: $left;
	right: $right;
	bottom: $bottom;
}

@mixin position-center($top: 50%, $left: 50%, $x: -50%, $y: -50%) {
	position: absolute;
	top: $top;
	left: $left;
	transform: translate($x, $y);
}

@mixin cross($width, $height, $thick) {
	width: $width;
	height: $height;

	&:before,
	&:after {
		content: "";
		transform-origin: center;
		transition-duration: 250ms;
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		margin: auto;
		background-color: $color1;
		background-image: none;
	}

	&:before {
		width: 100%;
		height: $thick;
	}

	&:after {
		width: $thick;
		height: 100%;
	}
}
