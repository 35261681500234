/**
* --------------------------------
* Accordion CSS
* --------------------------------
*/

.accordion-area {
	.accordion-ti {
		position: relative;
		display: block;
		padding: 10px 30px	10px 0;
		width: 100%;
		line-height: 21px;
		color: $color4;
		font-size: 15px;
		text-align: left;
		cursor: pointer;

		@include after-icon(-90px, 0);

		&::after {
			@include position(50%, auto, 0);

			transform: translate(0, -50%) rotate(180deg);
			transition: all .2s ease-in-out;
		}
	}

	.accordion-cont {
		position: relative;
		display: none;
		padding: 20px 35px;
		line-height: 20px;
		font-size: 14px;
		border-top: 1px solid $color-bg1;
		border-bottom: 1px solid $color-bg1;
	}

	> ul{
		> li {
			position: relative;

			&.active {
				.accordion-ti {
					font-weight: 700;

					&::after {
						transform: translate(0, -50%) rotate(0);
					}
				}
			}
		}
	}
}
