/**
* --------------------------------
* Keyframes CSS
* --------------------------------
*/

/* common */
@keyframes scroll-effect1 {
	0% {transform: translate(0, -100%);}
	100% {transform: translate(0, 100%);}
}

@keyframes zoom-center-effect1 {
	0% {transform: translate(-50%, -50%) scale(0);opacity: 0;}
	100% {transform: translate(-50%, -50%) scale(1);opacity: 1;}
}

@keyframes zoom-out-effect1 {
	0% {transform: scale(1.5);}
	100% {transform: scale(1);}
}

@keyframes fadein-effect1 {
	0% {transform: translate(0, 100px);opacity: 0;}
	100% {transform: translate(0, 0);opacity: 1;}
}

@keyframes background-effect1 {
	0% {background-size: 120% 120%;}
	100% {background-size: auto 100%;}
}

/* home */
@keyframes loadcont-effect1 {
	0% {transform: translate(-100%, -50%) scale(1);}
	50% {transform: translate(-50%, -50%) scale(1);}
	100% {border-color: rgba(255, 255, 255, .2);transform: translate(-50%, -50%) scale(.53);}
}

@keyframes loadcont-effect2 {
	0% {transform: translate(0, -50%) scale(1);}
	50% {transform: translate(-50%, -50%) scale(1);}
	100% {border-color: rgba(255, 255, 255, .2);transform: translate(-50%, -50%) scale(.53);}
}

@keyframes loadcont-effect3 {
	0% {opacity: 0;transform: scale(0);}
	100% {opacity: 1;transform: scale(1);}
}

@keyframes home-visual-btn {
	0% {transform: rotate(0deg);}
	100% {transform: rotate(180deg);}
}

@keyframes home-visual1 {
	0% {transform: scale(.7);opacity: 1;}
	15% {transform: scale(.9);opacity: 1;}
	100% {transform: scale(1.4);opacity: 0;}
}

@keyframes home-kma60th {
	0% {transform: translate(-50%, 0);}
	100% {transform: translate(-50%, -780px);}
}

@include tablet {
	@keyframes home-kma60th {
		0% {transform: translate(-50%, 0);}
		100% {transform: translate(-50%, rem(-1330px));}
	}
}
