// -----------------------------------------------------------------------------
// FONTS
// -----------------------------------------------------------------------------

@font-face {
	font-family: $webfont-family1;
	font-weight: 300;
	src: local($webfont-family1 + 'Light'),
	url($fonts-url + $webfont-family1 + '-Light.woff2') format('woff2'),
	url($fonts-url + $webfont-family1 + '-Light.woff') format('woff'),
	url($fonts-url + $webfont-family1 + '-Light.ttf') format('truetype');
}
@font-face {
	font-family: $webfont-family1;
	font-weight: 400;
	src: local($webfont-family1 + 'Regular'),
	url($fonts-url + $webfont-family1 + '-Regular.woff2') format('woff2'),
	url($fonts-url + $webfont-family1 + '-Regular.woff') format('woff'),
	url($fonts-url + $webfont-family1 + '-Regular.ttf') format('truetype');
}
@font-face {
	font-family: $webfont-family1;
	font-weight: 500;
	src: local($webfont-family1 + 'Medium'),
	url($fonts-url + $webfont-family1 + '-Medium.woff2') format('woff2'),
	url($fonts-url + $webfont-family1 + '-Medium.woff') format('woff'),
	url($fonts-url + $webfont-family1 + '-Medium.ttf') format('truetype');
}
@font-face {
	font-family: $webfont-family1;
	font-weight: 700;
	src: local($webfont-family1 + 'Bold'),
	url($fonts-url + $webfont-family1 + '-Bold.woff2') format('woff2'),
	url($fonts-url + $webfont-family1 + '-Bold.woff') format('woff'),
	url($fonts-url + $webfont-family1 + '-Bold.ttf') format('truetype');
}

@font-face {
	font-family: $webfont-family2;
	font-weight: 300;
	src: local($webfont-family2 + 'Light'),
	url($fonts-url + $webfont-family2 + '-Light.woff2') format('woff2'),
	url($fonts-url + $webfont-family2 + '-Light.woff') format('woff'),
	url($fonts-url + $webfont-family2 + '-Light.ttf') format('truetype');
}
@font-face {
	font-family: $webfont-family2;
	font-weight: 500;
	src: local($webfont-family2 + 'Medium'),
	url($fonts-url + $webfont-family2 + '-Medium.woff2') format('woff2'),
	url($fonts-url + $webfont-family2 + '-Medium.woff') format('woff'),
	url($fonts-url + $webfont-family2 + '-Medium.ttf') format('truetype');
}
@font-face {
	font-family: $webfont-family2;
	font-weight: 700;
	src: local($webfont-family2 + 'Bold'),
	url($fonts-url + $webfont-family2 + '-Bold.woff2') format('woff2'),
	url($fonts-url + $webfont-family2 + '-Bold.woff') format('woff'),
	url($fonts-url + $webfont-family2 + '-Bold.ttf') format('truetype');
}