// -----------------------------------------------------------------------------
// FUNCTIONS
// -----------------------------------------------------------------------------

/// align-items-center
@function fnalign-items-center($boxheight: null, $height: null) {
	@return (calc(($boxheight - $height) / 2));
}

/// rem
@function rem($px, $context: $font-size) {
	@return #{$px/$context}rem;
}

/// get-vw
@function get-vw($target, $vw-size: 1920) {
	@if type-of($target) == number {
		@return (($target * 100) / $vw-size) * 1vw;
	} @else if type-of($target) == list {
		$vw: ();
		@each $value in $target {
			@if type-of($value) == number {
				$vw: append($vw, (($value * 100) / $vw-size) * 1vw);
			} @else if type-of($value) == string {
				$vw: append($vw, $value);
			}
		}
		@return $vw;
	} @else {
		@return $target;
	}
}
