/**
* --------------------------------
* Header
* --------------------------------
*/

#header {
	background-color: $color7;
	border-bottom: 1px solid $color-bg3;

	&.bg {
    &::before {
      @include position(0, 0);
      content: '';
      width: 100vw;
      height: 97px;
      background-color: #FFFFFF;
      z-index: -1;
    }
  }

	.logo {
		display: inline-block;
		padding-top: 16px;
		width: 71px;
		height: 51px;

		a {
			@include background(0, -50px, $image-url-logo);

			width: 71px;
			height: 19px;
			text-indent: -9999em;
		}
	}

	.ham-nav {
		margin: 0 -20px;
		height: 0;
		overflow: hidden;

		.scrollbar {
			width: 100vw;
			height: calc(100vh - 98px);

			> ul {
				> li {
					display: inline-block;

					.sub {
						position: absolute;
						left: 0;
						top: -100vh;
						opacity: 0;
						width: 100%;
						background-color: $color7;
						transition: opacity .4s ease-out;

						.inner {
							padding-top: 20px;
							padding-bottom: 56px;
						}

						strong {
							display: inline-block;
							font-size: 32px;
							line-height: 44px;
							font-weight: 500;
							color: $color4;
						}

						ul {
							@include clear;
						}

						li {
							float: left;
							padding-top: 17px;
							padding-left: 0;
							width: 100%;

							strong {
								position: relative;
								margin-bottom: 20px;
								top: 0;
								line-height: 25px;
								font-size: 20px;
								font-weight: 400;
							}
						}

						a {
							display: block;
							padding: 0;
							line-height: 28px;
							color: $color5;
							font-size: 14px;

							strong {
								margin: -5px 0;
							}
						}
					}

					> a {
						display: inline-block;
						padding-top: 2px;
						padding-left: 10px;
						padding-right: 10px;
						line-height: 78px;
						color: $color4;
						font-size: 14px;
						font-weight: 500;
						letter-spacing: 0.025em;
					}
				}
			}
		}

		&.active{
			height: calc(100vh - 98px) !important;

			.scrollbar {
				height: calc(100vh - 98px) !important;
				max-height: calc(100vh - 98px) !important;

				> ul {
					> li {
						.sub {
							top: 0;
							opacity: 1;
						}
					}
				}
			}
		}
	}

	.util {
		@include position(0, null, 10px);

		display: flex;

		.btn-gnb {
			position: relative;
			width: 51px;
			height: 51px;
			text-align: left;
			text-indent: -9999em;

			> span {
				@include position(17px, 50%);

				width: 20px;
				height: 2px;
				transform: translateX(-50%);
				background-color: $color4;
				transition: all .3s;

				&:nth-child(1) {
					top: 17px;
				}

				&:nth-child(2) {
					top: 25px;
				}

				&:nth-child(3) {
					top: 33px;
				}
			}

			&.active {
				> span {
					&:nth-child(1) {
						top: 25px;
						transform: translateX(-50%) rotate(45deg);
					}

					&:nth-child(2) {
						opacity: 0;
					}

					&:nth-child(3) {
						top: 25px;
						transform: translateX(-50%) rotate(-45deg);
					}
				}
			}
		}

		.btn-search {
			position: relative;
			width: 39px;
			height: 51px;
			text-align: left;
			text-indent: -9999em;

			@include after-btn(0, -30px);
		}
	}

	&.transparent {
		background-color: transparent;
		border-bottom: 0;

		.logo {
			a {
				background-position-y: 0;
			}
		}

		.util {
			.btn-gnb {
				> span {
					background-color: $color7;
				}
			}

			.btn-search {
				&::after {
					background-position-y: 0;
				}
			}
		}
	}

	&.active {
		nav {
			ul {
				li {
					top: -500px;
					opacity: 0;
				}
			}
		}
	}
}

@media (max-width: 1024px) {
	#header {
		&.transparent {
			nav {
				border-color: transparent;

				.swiper-slide {
					button {
						color: $color7;
					}
				}
			}
		}

		nav {
			margin: 0 -20px;
			padding: 0 10px;
			height: 46px;
			border-top: 1px solid $color-bg5;

			.membership-cont {
				height: 0;
				overflow: hidden;
			}

			.swiper-slide {
				position: static;
				width: auto;

				button {
					display: block;
					padding: 0 10px;
					font-size: 15px;
					line-height: 44px;
					color: $color4;

					&.active {
						font-weight: 700;
					}
				}

				.sub {
					display: none;
					position: absolute;
					left: -10px;
					top: 46px;
					width: 100vw;
					height: 100vh;
					background-color: $color7;

					.inner {
						margin: 0;
					}

					strong {
						display: none;
					}

					li {
						height: 51px;
						overflow: hidden;
						transition: height .3s ease-in-out;

						button {
							display: block;
							position: relative;
							padding: 0 20px;
							width: 100%;
							text-align: left;
							font-size: 14px;
							line-height: 50px;
							color: $color4;
							border-bottom: 1px solid $color-bg5;

							.icon {
								display: inline-block;
								position: relative;
								width: 30px;
								height: 30px;
								font-size: 0;
								text-indent: -9999em;

								&.cross {
									@include cross(10px, 10px, 1px);
									@include position-center(50%, null, 0, -50%);

									right: 20px;

									&::after,
									&::before {
										background-color: $color6;
									}
								}
							}
						}

						a {
							display: block;
							padding: 0 20px;
							font-size: 13px;
							line-height: 46px;
							color: $color5;
							border-bottom: 1px solid $color-bg5;
							background-color: $color-bg1;
						}

						&.active {
							button {
								.icon {
									&.cross {
										&::after,
										&::before {
											background-color: $color4;
											transition: all .3s ease-in-out;
											border-radius: 1px;
										}

										&::before {
											height: 2px;
										}

										&::after {
											width: 2px;
											transform: rotate(90deg);
										}
									}
								}
							}
						}
					}
				}

				&.special-theme {
					.sub {
						a {
							display: block;
							padding: 0 20px;
							font-size: 14px;
							line-height: 50px;
							color: $color4;
							border-bottom: 1px solid $color-bg5;
						}
					}
				}
			}
		}
	}
}

@include desktop-min {
	#header {
		&.bg {
			&::before {
				height: 80px;
			}
		}

		.logo {
			@include vw(width, 236);

			padding-top: 30px;
			padding-left: 40px;
			height: 80px;

			a {
				transform: scale(1);
				transform-origin: 0 0;
			}
		}

		nav {
			display: inline-block;
			font-size: 0;
			vertical-align: top;

			> ul {
				transform: none !important;
				position: static;

				> li {
					position: static;
					width: auto;
					height: auto;
					display: inline-block;

					> button {
						display: inline-block;
						padding-top: 2px;
						padding-left: 10px;
						padding-right: 10px;
						line-height: 78px;
						color: $color4;
						font-size: 14px;
						font-weight: 500;
						letter-spacing: 0.025em;
					}

					> .sub {
						ul {
							li {
								button {
									.icon.cross {
										display: none;
									}
								}
							}
						}
					}

					&.special-theme {
						.sub {
							.inner {
								display: flex;
								flex-wrap: wrap;
								padding-top: 12px;
								padding-left: 0;
								padding-bottom: 48px;
								height: auto;
							}

							a {
								@include background(0, 0, $image-url+'common/gnb_specialtheme1.jpg', $background-size: 100%);
								@include vw(margin-top, 28);
								@include vw(margin-left, 28);
								@include vw(padding-top, 95);
								@include vw(padding-left, 24);
								@include vw(padding-right, 24);
								@include vw(height, 200);
								@include vw(width, 408);
								@include vw(font-size, 14);

								flex: 0 0 30%;
								position: relative;
								color: $color7;

								&:nth-of-type(3n+1) {
									margin-left: 0;
								}

								&.btn-organization {
									background-image: url($image-url+'common/gnb_specialtheme2.jpg');
								}

								&.btn-esg {
									background-image: url($image-url+'common/gnb_specialtheme3.jpg');
								}

								&.btn-interview {
									background-image: url($image-url+'common/gnb_specialtheme4.jpg');
								}

								&.btn-trnedm {
									background-image: url($image-url+'common/gnb_specialtheme5.jpg');
								}

								strong {
									@include vw(height, 65);
									@include vw(line-height, 26);
									@include vw(font-size, 20);

									position: relative;
									top: 0;
									margin: 0;
									display: block;
									width: 100%;
									color: $color7;
								}
							}
						}
					}

					&:active,
					&:hover {
						.sub {
							border-top: 1px solid $color-bg3;
							top: 80px;
							opacity: 1;
						}
					}
				}
			}

			.membership-cont {
				@include position(81px, null, 185px);

				padding: 0 10px;
				min-width: 142px;
				height: 0;
				background-color: $color7;
				transition: height .4s ease-in-out, padding .4s ease-in-out;
				overflow: hidden;
	
				li {
					a {
						display: block;
						padding-top: 0;
						color: $color5;
						font-size: 14px;
						line-height: 28px;

						strong {
							color: $color4;
							font-weight: 700;
						}
					}
				}

				&.active {
					padding-top: 16px;
					padding-bottom: 16px;
					height: 228px;
				}
			}


			.sub {
				@include position(-1000px, 0);

				opacity: 0;
				width: 100vw;
				background-color: $color7;
				transition: opacity .4s ease-out;

				.inner {
					padding-left: 138px;
					padding-bottom: 56px;
					height: 330px;
				}

				strong {
					@include position(37px, 0);

					display: inline-block;
					font-size: 32px;
					font-weight: 500;
					color: $color4;
				}

				ul {
					@include clear;
				}

				li {
					float: left;
					padding-top: 17px;
					padding-left: 28px;
					width: 20%;
					min-height: 165px;

					&:first-of-type {
						margin-left: 0;
					}

					button {
						position: relative;
						top: 0;
						line-height: 64px;
						font-size: 20px;
						font-weight: 400;
						color: $color4;
					}
				}

				a {
					display: block;
					padding: 5px 0;
					line-height: 18px;
					color: $color5;
					font-size: 14px;

					strong {
						margin: -5px 0;
					}
				}
			}
		}

		.ham-nav {
			margin: 0;

			.scrollbar {
				> ul {
					> li {
						display: block;

						.sub {
							@include position(81px, 0);

							top: -100vh;
							width: 100%;
							background-color: $color7;

							.inner {
								padding-left: 188px;
								padding-bottom: 56px;
							}

							strong {
								@include position(37px, 0);

								display: inline-block;
								font-size: 32px;
								font-weight: 500;
								color: $color4;
							}

							ul {
								@include clear;
							}

							li {
								float: left;
								padding-top: 17px;
								padding-left: 28px;
								width: 20%;
								min-height: 222px;

								&:first-of-type {
									margin-left: 0;
								}

								&:nth-child(6),
								&:nth-child(7),
								&:nth-child(8),
								&:nth-child(9),
								&:nth-child(10) {
									min-height: 278px;
								}

								strong {
									position: relative;
									margin-bottom: 20px;
									top: 0;
									line-height: 25px;
									font-size: 20px;
									font-weight: 400;
								}
							}

							a {
								display: block;
								padding: 0;
								line-height: 28px;
								color: $color5;
								font-size: 14px;

								strong {
									margin: -5px 0;
								}
							}
						}

						> a {
							display: inline-block;
							padding-top: 2px;
							padding-left: 10px;
							padding-right: 10px;
							line-height: 78px;
							color: $color4;
							font-size: 14px;
							font-weight: 500;
							letter-spacing: 0.025em;
						}
					}
				}
			}

			&.active{
				height: calc(100vh - 80px) !important;

				.scrollbar {
					height: calc(100vh - 80px) !important;
					max-height: calc(100vh - 80px) !important;

					> ul {
						> li {
							.sub {
								top: 0;
							}
						}
					}
				}
			}
		}

		.util {
			right: 0;

			.btn-gnb {
				width: 48px;
				height: 80px;

				> span {
					width: 22px;

					&:nth-child(1) {
						top: 31px;
					}

					&:nth-child(2) {
						top: 39px;
					}

					&:nth-child(3) {
						top: 47px;
					}
				}

				&.active {
					> span {
						&:nth-child(1),
						&:nth-child(3) {
							top: 39px;
						}
					}
				}
			}

			.btn-search {
				width: 42px;
				height: 80px;

				&::after {
					transform: translate(-50%, -50%) scale(1.1);
				}
			}

			.btn-membership {
				display: inline-block;
				margin-top: 0;
				margin-left: 10px;
				margin-right: 12px;
				line-height: 80px;
				color: $color4;
				font-size: 16px;
				letter-spacing: 0.025em;
			}

			.btn-kmalearn {
				position: relative;
				padding-left: 20px;
				width: 220px;
				height: 80px;
				background-color: $color4;
				text-align: left;
				text-indent: -9999em;

				@include after-btn(-400px, 0, 200px, 60px, 600px);

				&::after {
					top: 12px;
					left: auto;
					right: 0;
					transform: translate(0, 0);
				}
			}
		}
	
		&.transparent {
			nav {
				> ul {
					> li {
						> button {
							color: $color7;
						}
					}
				}
			}

			.membership-cont {
				li {
					a {
						color: $color5;
					}
				}
			}

			.util {
				.btn-membership {
					color: $color7;
				}
			}
		}
	}
}

@include desktop {
	#header {
		.logo {
			padding-top: 27px;

			a {
				transform: scale(1.367);
			}
		}

		nav {
			> ul {
				> li {
					> button {
						padding-left: 24px;
						padding-right: 24px;
						font-size: 18px;
					}

					&.special-theme {
						.sub {
							a {
								margin-top: 28px;
								margin-left: 28px;
								padding-top: 95px;
								padding-left: 24px;
								padding-right: 24px;
								height: 200px;
								font-size: 14px;

								strong {
									height: 65px;
									line-height: 26px;
									font-size: 20px;
								}
							}
						}
					}
				}
			}

			.membership-cont {
				@include position(81px, null, 200px);
			}

			.sub {
				.inner {
					margin: 0 auto;
					max-width: 1280px;
					padding-left: 216px;
				}

				li {
					width: 190px;
				}
			}
		}

		.util {
			.btn-gnb {
				width: 58px;
			}
			.btn-search {
				width: 52px;
			}
			.btn-membership {
				margin-left: 18px;
				margin-right: 39px;
				font-size: 16px;
			}
		}
	}
}

@include desktop-max {
	#header {
		.logo {
			width: 230px;
		}
	}
}
