/**
* --------------------------------
* Popup CSS
* --------------------------------
*/

/* popup */
.popup {
	position: fixed;
	top: 100%;
	left: 0;
	width: 100vw;
	height: 100%;
	background: rgba(0, 0, 0, .5);
	opacity: 0;
	z-index: 100;

	>.inner {
		@include position-center(80%, 50%, -50%, 0);

		margin: 0;
		padding: 0;
		min-width: 320px;
		min-height: 320px;
		width: calc(100% - 40px);
		background: $color7;
		overflow: hidden;
		transition: all .3s ease-in-out;

		.btn-close {
			@include position(0, auto, 0);

			width: 60px;
			height: 60px;
			background-color: rgba(255, 255, 255, .9);
			border-radius: 50%;
			z-index: 3;

			@include after-btn(-120px, -60px);
		}

		> header {
			position: relative;
			padding: 0 20px;
			height: 60px;
			z-index: 1;
	
			h2 {
				line-height: 60px;
				color: $color4;
				font-size: 15px;
				font-weight: 700;
			}
		}

		.contents {
			padding: 0 20px 20px;
			max-height: calc(100vh - 200px);
			overflow: auto;
		}
	}

	main {
		position: relative;
		padding: 0;
		max-width: 100%;
		text-align: center;
		z-index: 0;

		.innerfull {
			margin: 0 -20px;
		}
	}

	footer {
		position: absolute;
		bottom: 0;
		margin-top: 0;
		padding: 32px;
		width: 100%;
		background-color: $color7;
		z-index: 2;

		.btnarea,
		.btns {
			margin-top: 0;
			background-color: $color7;
		}
	}

	&.active {
		top: 0;
		opacity: 1;

		>.inner {
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
		}
	}

	&.full {
		background: transparent;
		opacity: 0;

		> .inner {
			left: 0;
			min-width: 100%;
			height: 100%;
			border-radius: 0;
			transform: none;
			transition: all 0s ease-in-out;

			> header {
				padding: 0 60px;

				h2 {
					text-align: center;
					overflow: hidden;
					text-overflow: ellipsis;
					white-space: nowrap;
				}
			}

			.innerfull {
				margin: 0 -20px;
			}
		}

		.contents {
			max-width: 100vw;
			height: calc(100% - 60px);
			overflow: hidden;
			text-align: left;
		}

		main {
			width: 100%;
			height: 100%;
			text-align: left;
		}

		footer {
			display: none;
			position: fixed;
			bottom: 0;
			left: 0;
			width: 100%;

			.btns {
				.btn {
					height: 40px;
					line-height: 38px;
				}
			}
		}

		&.active {
			top: 0;
			opacity: 1;

			>.inner {
				top: 0;

				footer {
					display: block;
				}
			}
		}
	}

  .popup-item {
    & + .popup-item {
      margin-top: 30px;
    }
  }
  .graybox {
    padding: 10px;
    background-color: $color-bg1;
    color: $color5;
    font-size: 14px;
    line-height: 24px;
    & + .item-desc {
      margin-top: 20px;
    }
    & + .popup-item {
      margin-top: 30px;
    }
  }
  .item-tit {
    font-size: 18px;
    font-weight: 500;
    line-height: 30px;
    & + .item-desc,
    & + .item-subtit {
      margin-top: 10px;
    }
  }
  .item-subtit {
    font-size: 16px;
    line-height: 20px;
    font-weight: 500;
    & + .item-desc,
    & + .item-disc {
      margin-top: 10px;
    }
  }
  .item-desc {
    font-size: 15px;
    line-height: 26px;
    color: $color5;
    & + .item-desc,
    & + .item-disc,
    & + .item-subtit {
      margin-top: 10px;
    }
  }
  .item-disc {
    font-size: 14px;
    line-height: 26px;
    color: $color5;
    padding-left: 13px;
    & + .item-disc,
    & + .item-desc,
    & + .item-subtit {
      margin-top: 10px;
    }
  }
  .item-txt-list {
    margin-top: 20px;
  }
  .item-link {
    font-size: 14px;
    line-height: 22px;
    font-weight: 500;
    color: $color5;
    margin-top: 10px;
    text-decoration: underline;
    &.blue {
      color: $color2;
    }
  }
  .table_wrap {
    margin-top: 5px;
    table {
      width: 300%;
      thead {
        tr {
          border-top: 2px solid $color4;
          background-color: $color-bg1;
        }
        th {
          text-align: center;
          font-size: 14px;
					font-weight: 400;
          line-height: 44px;
        }
      }
      tbody {
        th {
          font-size: 14px;
          line-height: 44px;
					font-weight: 400;
          padding: 4px 0;
          background-color: $color-bg1;
          text-align: center;
        }
        td {
          font-size: 14px;
          line-height: 22px;
          padding: 15px 10px;
          text-align: center;
          color: $color5;
          border-bottom: 1px solid $color-bg1;
        }
      }
    }
    & + .item-disc,
    & + .item-desc {
      margin-top: 10px;
    }
  }
}

.popsearch {
	transition-delay: 0ms;
	position: fixed;
	top: -100%;
	left: 0;
	width: 100vw;
	height: 100%;
	background: rgba(0, 0, 0, .5);
	z-index: -2;
	opacity: 0;

	>.inner {
		@include background(50%, 0%, $image-url-common+'popup_search_bg.jpg', $background-size: cover);

		transition: transform .4s ease-out;
		transform: translateY(-100vh);
		position: relative;
		margin: 0;
		padding-top: 97px;
		width: 100%;
		height: 630px;
		max-width: none;

		>.contents {
			padding: 70px 25px 20px;
			max-width: 625px;
			max-height: 483px;
			margin: 0 auto;

			.inner {
				padding-top: 70px;
				margin: 0 25px;
			}

			.search-input {
				position: relative;

				input[type="text"] {
					height: auto;
					padding: 15px 0px;
					color: $color7;
					font-size: 20px;
					line-height: 30px;
					background-color: transparent;
					border: none;
					border-bottom: 2px solid $color7;
				}

				.search-btn {
					position: absolute;
					top: 15px;
					right: 0;

					width: 35px;
					height: 35px;
					font-size: 0;

					&::after {
						@include background(-2px, -2px, $url: $image-url-btn, $background-size: 400px);

						content: '';
						width: 35px;
						height: 35px;
					}
				}
			}

			.info.nodata {
				padding: 145px 0 0;
				text-align: center;

				p {
					padding-top: 80px;
					position: relative;
					color: $color6;
					font-size: 14px;
					
					&::after {
						content: '';
						position: absolute;
						top: 0;
						left: 50%;
						transform: translate(-50%, 0);
						width: 50px;
						height: 50px;
						background-size: cover;
						background-repeat: no-repeat;
						background-image: url($image-url-common+'info_nodata.png');
					}
				}
			}

			.recent-searches {
				margin-top: 18px;

				.tit-area {
					@include clear;

					.ti-dot {
						color: $color2;
						padding-right: 13px;
						font-size: 16px;
						line-height: 19px;
						font-weight: 700;
						vertical-align: bottom;

						&::before {
							bottom: 0px;
							width: 6px;
							height: 6px;
						}
					}

					.del-all {
						float: right;
						color: $color6;
						font-size: 13px;
						line-height: 19px;
					}
				}

				

				ul {
					margin-top: 16px;

					li {
						@include clear;

						color: $color7;
						font-size: 14px;
						line-height: 30px;

						.icon.close {
							@include after-btn(-75px, -195px, 30px, 30px, $background-size: 600px);

							float: right;
							position: relative;
							width: 25px;
							height: 30px;
						}

						&+li {
							margin-top: 0px;
						}
					}
				}
			}
		}
	}

	.btn-close {
		position: absolute;
		font-size: 0;
		background-color: transparent;
		top: 117px;
		right: 14px;
		width: 30px;
		height: 30px;
		
		&::after {
			content: '';
			background-image: url(/images/hub/common/btn.png);
			background-position: -555px -195px;
			background-size: 600px;
			width: 30px;
			height: 30px;
			display: inline-block;
		}
	}
}

.popup.full.popagree {
	> .inner {
		header {
			padding-top: 20px;
			padding-bottom: 20px;
			padding-left: 20px;
			height: 77px;

			h2 {
				padding-top: 17px;
				text-align: left;
				font-size: 20px;
				line-height: 20px;
			}

			& + .scrollbar {
				height: calc(100% - 190px) !important;
				max-height: calc(100% - 190px) !important;
			}
		}

		footer {
		.btns {
			button.btn {
				width: 200px;
				height: 48px;
				font-size: 16px;
				line-height: 46px;
			}
		}
	}
	}
}

@include tablet {
	.popup {
		&.full {
			background: rgba(0, 0, 0, 0.5);

			> .inner {
				left: 50%;
				transform: translateX(-50%);
				max-width: 940px;
				min-width: auto;
				max-height: 80%;
				height: auto;

				> header {
					padding: 0 rem(32px);
					height: rem(79px);

					h2 {
						padding-top: rem(5px);
						line-height: rem(74px);
						font-size: rem(20px);
						text-align: left;
					}
				}

				.contents {
					padding: 0 rem(32px) rem(32px);
				}

				footer {
					.btns {
						.btn {
							height: rem(48px);
							line-height: rem(48px);
						}
					}
				}

				.btn-close {
					width: 66px;
					height: 66px;

					&::after {
						transform: translate(-50%, -50%) scale(.8);
						opacity: .5;
					}
				}
			}
		}

		.graybox {
			padding: 20px;
		}
		.table_wrap table {
			width: 100%;
		}

		&.full.popagree {
			> .inner {
				header {
					padding-top: 32px;
					padding-bottom: 30px;
					padding-left: 32px;
					height: 112px;

					h2 {
						padding-top: 29px;
					}

					& + .scrollbar {
						height: calc(80% - 224px) !important;
						max-height: calc(80% - 224px) !important;
					}
				}

				.contents {
					max-width: 100%;
					max-height: calc(80vh - 224px);

					main {
						padding-bottom: 80px;
					}
				}
			}
		}
	}

	.popsearch {
		>.inner {
			padding-top: 81px;
			width: 100vw;
			height: 753px;
			max-width: none;

			>.contents {
				padding: rem(55px) 0 rem(86px);
				max-width: 665px;
				max-height: 620px;
				height: 672px;

				.inner {
					padding: 45px 20px;
				}

				.search-input {
					input[type="text"] {
						font-size: rem(25px);
						line-height: rem(30px);
					}

					.search-btn {
						top: 15px;
						right: 0;
						width: 35px;
						height: 35px;

						&::after {
							@include background(-2px, -2px, $url: $image-url-btn, $background-size: 400px);

							width: 35px;
							height: 35px;
						}
					}
				}

				.info.nodata {
					padding: rem(125px) 0 rem(150px);
					text-align: center;

					p {
						padding-top: rem(75px);
						position: relative;
						color: $color6	;
						font-size: rem(16px);

					}
				}

				.recent-searches {
					margin-top: rem(30px);
					padding: 0 rem(30px);

					.tit-area {
						.ti-dot {
							padding-right: rem(12px);
							font-size: rem(20px);
							line-height: rem(19px);
						}

						.del-all {
							font-size: rem(13px);
							line-height: rem(19px);
						}
					}

					

					ul {
						margin-top: rem(30px);

						li {
							font-size: rem(16px);
							line-height: rem(30px);

							&+li {
								margin-top: rem(10px);
							}
						}
					}
				}
			}
		}

		.btn-close {
			@include after-btn(-270px, -60px, 30px, 30px, $background-size: 300px);

			top: rem(130px);
			right: rem(50px);
		}
	}
}