/**
* --------------------------------
* Button CSS
* --------------------------------
*/

button {
	font-family: $font-family1;
	color: $color7;
	padding: 0;
	border: 0;
	background: transparent;
	cursor: pointer;
	-webkit-tap-highlight-color: transparent;
}

.btn {
	position: relative;
	display: inline-block;
	padding: 0 10px;
	height: 44px;
	line-height: 42px;
	color: $color4;
	font-size: 14px;
	font-weight: 700;
	text-align: center;
	border: 1px solid $color4;
	background: $color7;
	vertical-align: top;
	overflow: hidden;

	&[class*="small"] {
		padding: 0 5px;
		height: 32px;
		line-height: 30px;
		font-size: 13px;
		font-weight: 400;
	}

	&[class*="fill"] {
		color: $color7;
		background-color: $color4;
		border-color: $color4;
	}

	&[disabled] {
		color: $color6 !important;
		border-color: $color-bg1 !important;
		background-color: $color-bg1 !important;
		pointer-events: none;

		&[class*="fill"] {
			background-color: $color7 !important;
		}
	}

	&[class*="blue"] {
		color: $color1;
		border-color: $color1;

		&[class*="fill"] {
			color: $color7;
			background-color: $color1;
		}
	}

	&[class*="gray"] {
		color: $color6;
		border-color: $color6;

		&[class*="fill"] {
			color: $color7;
			background-color: $color6;
		}
	}

	&[class*="white"] {
		color: $color7;
		background-color: transparent;
		border-color: $color7;

		&[class*="fill"] {
			color: $color4;
			background-color: $color7;
		}
	}
}

.btns {
	display: flex;
	margin-top: 40px;
	justify-content: center;
	position: relative;
	text-align: center;
	font-size: 0;

	.btn {
		flex-grow: 0;
		margin: 0 4px;
		width: 100%;

		&.small {
			margin: 0 3px;
		}

		&:first-child {
			margin-left: 0;
		}

		&:last-child {
			margin-right: 0;
		}
	}
}

.btn-more {
	display: none;
	margin-top: 20px;
	width: 100%;
	line-height: 32px;
	color: $color5;
	font-size: 14px;
	text-align: center;

	&.active {
		display: block;
	}

	.icon-more {
		margin-top: 1px;
	}
}

.btn-line {
	display: inline-block;
	line-height: 18px;
	color: $color6;
	font-size: 13px;
	border-bottom: 1px solid $color6;
}

.btn-play {
	position: relative;
	display: inline-block;
	width: 32px;
	height: 32px;
	border: 1px solid $color7;
	border-radius: 50%;
	text-align: left;
	text-indent: -9999em;

	@include after-btn(-150px, -60px, $margin: 0 0 0 2px);
}

@include tablet {
	.btn {
		padding: 0 rem(20px);
		height: rem(48px);
		line-height: rem(46px);
		font-size: rem(16px);

		&[class*="small"] {
			padding: 0 rem(10px);
			height: rem(40px);
			line-height: rem(38px);
			font-size: rem(16px);
		}
	}

	.btns {
		.btn {
			margin: 0 6px;
			width: 100%;
	
			&.small {
				margin: 0 6px;
			}
	
			&:first-child {
				margin-left: 0;
			}
	
			&:last-child {
				margin-right: 0;
			}
		}
	}

	.btn-more {
		margin-top: rem(20px);
		line-height: rem(60px);
		font-size: rem(18px);
	
		.icon-more {
			margin-top: rem(9px);
			margin-left: rem(2px);

			&::after {
				transform: translate(-50%, -50%) scale(.6);
			}
		}
	}
}

@include desktop-min {
	.btn-more {
		.icon-more {
			margin-top: rem(14px);

			&::after {
				transform: translate(-50%, -50%) scale(1);
			}
		}
	}

	.btn-line {
		padding-top: 4px;
		line-height: rem(18px);
		font-size: rem(14px);
	}
}
