/**
* --------------------------------
* layout
* --------------------------------
*/

body {
	&.fixed {
		position: fixed;
		overscroll-behavior: contain;
		overflow-y: scroll;

		#wrap {
			#header {
				transition: top 0s;
			}
		}
	}
}

#wrap {
	position: relative;
	width: 100%;

	&.down {
		#header {
			top: -98px;
		}
	}
}

#header {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	transition: top .2s;
	z-index: 30;

	> .inner {
		position: static;
	}
}

#container {
	position: relative;
	width: 100%;
}

#contents {
	position: relative;
	padding: 98px 0 0 0;
	width: 100%;
	min-height: calc(100vh - 250px);
}

#footer {
	position: relative;
	padding: 50px 0 40px;
	background-color: $color-bg1;
	z-index: 20;
}

.inner {
	position: relative;
	margin: 0 20px;

	.innerfull {
		margin: 0 -20px;
		width: calc(100% + 40px);
	}
}

.innerfull {
	position: relative;
	width: 100%;
}

@include tablet {
	#header {
		[data-respond='mobile'] {
			display: block;
		}
		[data-respond='pc'] {
			display: none;
		}
	}

	#footer {
		padding-top: 39px;
	}

	.inner {
		.innerfull {
			margin: 0;
			width: 100%;
		}
	}
}

@include desktop-min {
	#wrap {
		&.down {
			#header {
				top: -81px;
			}
		}
	}

	#header {
		height: 81px;

		> .inner {
			margin: 0;
			max-width: 100%;
		}

		&.active {
			top: -81px;
		}

		[data-respond='mobile'] {
			display: none;
		}
		[data-respond='pc'] {
			display: block;
		}
	}

	#contents {
		padding-top: 81px;
	}

	.inner {
		margin: 0 40px;
	}
}

@include desktop {
	.inner {
		margin: 0 auto;
		max-width: 1280px;
	}
}

@include desktop-max {
	#header {
		> .inner {
			margin: 0 auto;
			max-width: 1800px;
		}
	}
}
