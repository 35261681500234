/**
* --------------------------------
* Contents
* --------------------------------
*/

/* subtop */
.subtop-area {
	.title {
		padding-top: 30px;
	
		h2 {
			line-height: 82px;
			font-size: 30px;
			font-family: $webfont-family2;
		}
	}
	
	.visual {
		position: relative;
		display: flex;
		height: 373px;
		overflow: hidden;
		align-items: center;
		justify-content: center;
		text-align: center;
		
		.inner {
			margin: 0;
		}

		.bg {
			@include position(0, 0);
			@include background(50%, 100%, $image-url-service+'subtop_consulting_edu.jpg', $background-size: cover);

			width: 100%;
			height: 373px;
			transform: scale(1);
			opacity: 0;
		}

		h3 {
			color: $color7;
			font-size: 34px;
		}

		p {
			margin-top: 20px;
			line-height: 21px;
			color: $color7;
			font-size: 15px;

			span {
				display: block;
			}
		}

		.btns {
			.btn {
				flex-shrink: 0;
				width: 101px;
				color: $color7;
				font-size: 14px;
				border-color: rgba(255, 255, 255, .5);
				border-radius: 32px;
				background-color: transparent;
			}
		}
	}

	&.active {
		.visual {
			.bg {
				opacity: 1;
				animation: zoom-out-effect1 .6s both;
			}

			h3 {
				animation: fadein-effect1 .6s .4s ease-in-out both;
			}

			p {
				animation: fadein-effect1 .6s .6s ease-in-out both;
			}

			.btn {
				animation: fadein-effect1 .6s .8s ease-in-out both;
			}
		}
	}
}

@include tablet {
	.subtop-area {
		.title {
			padding-top: rem(45px);
		
			h2 {
				line-height: rem(116px);
				font-size: rem(40px);
			}
		}
		
		.visual {
			height: rem(400px);

			.bg {
				height: rem(400px);
			}

			h3 {
				margin-top: rem(14px);
				font-size: rem(48px);
			}

			p {
				margin-top: rem(25px);
				line-height: rem(30px);
				font-size: rem(20px);

				span {
					display: inline-block;
				}
			}

			.btns {
				margin-top: rem(42px);
				margin-left: auto;
				margin-right: auto;
				width: rem(504px);

				.btn {
					width: rem(160px);
					font-size: rem(16px);
					border-radius: rem(20px);
				}
			}
		}
	}
}

/* popup */
.popup.popinquiry {
	.contents {
		main {
			padding-bottom: 100px;
		}
	}

	.form-entry {
		&.form-agree {
			margin-top: 20px;
		}
	}
}

@include tablet {
	.popup.popinquiry {	
		.form-entry {
			textarea {
				height: rem(220px);
			}

			&.form-agree {
				margin-top: rem(40px);
			}
		}

		footer {
			.btns {
				.btn {
					width: rem(240px);
				}
			}
		}
	}
}

/* cont-swiper */
.cont-swiper {
	overflow: hidden;

	.swiper-container {
		margin: 0 20px;
	}

	.swiper-slide {
		width: auto;

		> div,
		> a {
			position: relative;
			display: block;
		}
	}
}

/* contfull-swiper */
.contfull-swiper {
	.swiper-pagination {
		.swiper-pagination-bullet {
			margin: 0 6px;
			width: 5px;
			height: 5px;
			background-color: $color7;
			opacity: .5;
		}

		.swiper-pagination-bullet-active {
			opacity: 1;
		}
	}
}

/* progressbar-swiper */
.progressbar-swiper {
	position: relative;
	padding-bottom: 30px;
	overflow: hidden;

	.swiper-container {
		margin: 0 20px;
	}

	.swiper-pagination {
		top: auto;
		left: 20px;
		right: 20px;
		bottom: 0;
		width: calc(100% - 40px);
		height: 1px;
		background-color: $color-bg2;

		.swiper-pagination-progressbar-fill {
			background-color: $color4;
		}
	}
}

@include tablet {
	.progressbar-swiper {
		padding-bottom: rem(50px);

		.swiper-pagination {
			left: 20px;
			right: 20px;
		}
	}
}

@include desktop-min {
	.cont-swiper {
		.swiper-container {
			margin: 0 40px;
		}
	}

	.progressbar-swiper {
		.swiper-container {
			margin: 0 40px;
		}

		.swiper-pagination {
			left: 40px;
			right: 40px;
			width: calc(100% - 80px)
		}
	}
}

@include desktop {
	.cont-swiper {
		.swiper-container {
			margin: 0;
		}
	}

	.progressbar-swiper {
		.swiper-container {
			margin: 0;
		}

		.swiper-pagination {
			left: 0;
			right: 0;
			width: 100%;
		}
	}
}

/* infotxt-area */
.infotxt-area {
	> strong {
		display: block;
		margin-bottom: 12px;
		font-size: 15px;
		font-weight: 400;
	}

	li {
		position: relative;
		padding: 1px 0 1px 8px;
		line-height: 20px;
		color: $color5;
		font-size: 14px;

		span {
			display: block;
		}

		br {
			display: none;
		}

		@include dot(3px, 3px, $color5);

		&::before {
			top: 8px;
		}

		&.dot-none {
			padding-left: 0;

			&::before {
				display: none;
			}
		}
	}
}

@include tablet {
	.infotxt-area {
		> strong {
			margin-bottom: rem(16px);
			font-size: rem(20px);
		}

		li {
			padding: rem(2px) 0 rem(2px) rem(10px);
			line-height: rem(22px);
			font-size: rem(16px);

			span {
				display: inline;
			}

			br {
				display: block;
			}
	
			&::before {
				top: rem(11px);
			}
		}
	}
}

/* link-plus */
.link-plus {
	line-height: 21px;

	.icon-plus {
		position: relative;
		display: inline-block;
		width: 20px;
		height: 20px;
		border-radius: 50%;
		vertical-align: top;
		transition: all .2s;

		&::after {
			@include position-center;

			content: '';
			width: 7px;
			height: 7px;
			border-radius: 50%;
			background-color: $color2;
		}
	}

	&:hover,
	&:active,
	&.active {
		.icon-plus {
			background-color: $color2;

			&::before {
				@include position-center;

				content: '';
				width: 1px;
				height: 8px;
				background-color: $color7;
			}

			&::after {
				@include position-center;

				content: '';
				width: 8px;
				height: 1px;
				border-radius: 0;
				background-color: $color7;
			}
		}
	}
}

@include tablet {
	.link-plus {
		line-height: 24px;

		.icon-plus {
			margin-left: 7px;
			width: 32px;
			height: 32px;

			&::after {
				@include position-center;
	
				content: '';
				width: 12px;
				height: 12px;
				border-radius: 50%;
				background-color: $color2;
			}
		}

		&:hover,
		&:active,
		&.active {
			.icon-plus {
				&::before {
					width: 2px;
					height: 12px;
				}

				&::after {
					width: 12px;
					height: 2px;
				}
			}
		}
	}
}

/* ti-dot */
.ti-dot {
	position: relative;
	display: inline-block;
	padding-right: 10px;

	@include dot(6px, 6px, $color2);

	&::before {
		top: auto;
		left: auto;
		right: 0;
		bottom: 18px;
	}
}

@include tablet {
	.ti-dot {
		padding-right: 14px;

		&::before {
			bottom: 24px;
			width: 10px;
			height: 10px;
		}
	}
}

/* scrollbar */
.scrollbar {
	>.scroll-element {
		div {
			position: absolute;
			top: 4px;
			bottom: 2px;
			width: 100%;
		}

		&.scroll-y {
			position: absolute;
			top: 0;
			right: 4px;
			width: 4px;
			height: 100%;
		}

		&.scroll-x {
			position: absolute;
			bottom: 8px;
			left: 4px;
			right: 4px;
			height: 4px;

			.scroll-bar {
				top: 0;
				bottom: 0;
				height: 4px;
			}
		}

		.scroll-element_track {
			top: 0;
			border-radius: 0;
		}

		.scroll-bar {
			background: $color-bg2 !important;
			border-radius: 0;
			cursor: pointer;
		}
	}
}

/* .swiper-button */
.swiper-button-prev,
.swiper-button-next {
	text-indent: -9999em;

	@include after-icon(-60px, 0);
}
.swiper-button-prev {
	&::after {
		transform: translate(-50%, -50%) rotate(180deg);
	}
}

/* infodata nodata */

.infodata.nodata {
	padding: 80px 0;

	p {
		position: relative;
		padding-top: 70px;
		text-align: center;
		font-size: 14px;
		line-height: 22px;
		color: $color5;

		&::after {
			content: '';
			position: absolute;
			top: 0;
			left: 50%;
			transform: translate(-50%, 0);
			width: 50px;
			height: 50px;
			background-size: cover;
			background-repeat: no-repeat;
			background-image: url($image-url-common+'info_nodata.png');
		}
	}
}

@include tablet {
	.infodata.nodata {
		padding: rem(160px) 0;

		p {
			font-size: 16px;
		}
	}
}