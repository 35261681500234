/**
* --------------------------------
* Tabs CSS
* --------------------------------
*/

.tabs {
	position: relative;
	margin-top: 2px;
	height: 54px;
	background-color: $color7;
	font-size: 0;

	@include before-line(100%, 1px, $color: $color-bg5);

	&::before {
		top: auto;
		bottom: 0;
	}

	a {
		position: relative;
		display: inline-block;
		margin: 0 16px;
		padding: 0 5px;
		line-height: 52px;
		color: $color5;
		font-size: 14px;

		&:hover,
		&.active {
			color: $color1;
			font-weight: 700;
			border-bottom: 2px solid $color1;
		}
	}

	&.swiper {
		z-index: 10;

		.swiper-slide {
			width: auto;

			&:first-of-type {
				a {
					margin-left: 20px;
				}
			}

			&:last-of-type {
				a {
					margin-right: 20px;
				}
			}
		}
	}

	&.sticky {
		position: sticky;
		top: 98px;
		transition: all .2s;
	}
}

#wrap {
	&.down {
		.tabs {
			&.sticky {
				top: 0;
			}
		}
	}
}

@include tablet {
	.tabs {
		margin-top: 3px;
		height: 69px;
		text-align: center;

		a {
			margin: 0 50px;
			padding: 0 7px;
			line-height: 67px;
			font-size: rem(18px);
		}
	}
}

@include desktop-min {
	.tabs {
		&.swiper {
			.swiper-container {
				margin: 0 auto;
				max-width: 1280px;
			}

			.swiper-wrapper {
				justify-content: space-around;

				.swiper-slide {
					width: auto;

					a {
						margin: 0;
					}

					&:first-of-type {
						a {
							margin-left: 0;
						}
					}

					&:last-of-type {
						a {
							margin-right: 0;
						}
					}
				}
			}
		}

		&.sticky {
			top: 81px;
		}
	}
}
