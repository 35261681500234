// -----------------------------------------------------------------------------
// VARIABLES
// -----------------------------------------------------------------------------

/// url
$base-url: '../../' !default;
$fonts-url: $base-url + 'font/hub/' !default;
$image-url: $base-url + 'images/hub/' !default;
$image-url-form: $image-url + 'common/form.png' !default;
$image-url-icon: $image-url + 'common/icon.png' !default;
$image-url-btn: $image-url + 'common/btn.png' !default;
$image-url-logo: $image-url + 'common/logo.png' !default;
$image-url-common: $image-url + 'common/' !default;
$image-url-home: $image-url + 'home/' !default;
$image-url-service: $image-url + 'service/' !default;
$image-url-insight: $image-url + 'insight/' !default;
$image-url-about: $image-url + 'about/' !default;
$image-url-special: $image-url + 'special/' !default;
$image-url-recruit: $image-url + 'recruit/' !default;

/// fonts
$webfont-family1: 'SUIT';
$font-family1: $webfont-family1;

$webfont-family2: 'montserrat';
$font-family2: $webfont-family2;

$font-size: 16px;
$font-weight: 400;
$line-height: 1;
$letter-spacing: 0;

/// color
$color1: #0000a3 !default;
$color2: #3868f4 !default;
$color3: #e78f4a !default;
$color4: #000000 !default;
$color5: #767676 !default;
$color6: #a9a9a9 !default;
$color7: #ffffff !default;

$color-bg1: #f4f4f4 !default;
$color-bg2: #d4d4d4 !default;
$color-bg3: #efefef !default;
$color-bg4: #2f302f !default;
$color-bg5: #eeeeee !default;
$color-bg6: #eff3ff !default;
$color-bg7: #f8f8f8 !default;



/// info ///
/// - button class 순서
/// btn darkgray fill big