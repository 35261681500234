/**
* --------------------------------
* Footer
* --------------------------------
*/

#footer {

	.btn-follow {
		position: fixed;
		right: 20px;
		bottom: 40px;
	
		.btn-top {
			position: relative;
			display: none;
			width: 50px;
			height: 50px;
			background-color: $color7;
			border-radius: 50%;
			box-shadow: 2px 0 4px rgba(0, 0, 0, .2);
			opacity: 0;
			transition: opacity .4s;
	
			@include after-btn(-30px, -60px);
		}

		.btn-qna {
			position: relative;
			margin-top: 16px;
			display: block;
			width: 50px;
			height: 50px;
			background-color: rgba(229, 229, 229, .9);
			border-radius: 50%;
			box-shadow: 2px 0 4px rgba(0, 0, 0, .2);
			text-align: left;
			text-indent: -9999em;

			@include after-btn(0, -60px);
		}

		&.active {
			.btn-top {
				display: block;
				opacity: 1;
			}

			&.fixed {
				top: -156px;
			}
		}

		&.fixed {
			position: absolute;
			top: -106px;
			bottom: auto;

			&.acitve {
				top: -156px;
			}
		}
	}

	.logo {
		@include background(0, -50px, $image-url-logo);
		
		display: inline-block;
		width: 70px;
		height: 19px;
		text-indent: -9999em;
	}

	nav {
		margin-top: 20px;
		display: flex;
		flex-wrap: wrap;

		a {
			flex: 1 1 40%;
			line-height: 35px;
			color: $color5;
			font-size: 13px;
			font-weight: 400;

			strong {
				color: $color4;
				font-size: 13px;
				font-weight: 700;
			}
		}
	}

	.qna {
		.btns {
			margin-top: 20px;

			.btn {
				height: 32px;
				line-height: 30px;
				font-size: 13px;
				border-radius: 2px;
			}
		}

		p {
			margin-top: 5px;
			color: $color5;
			font-size: 13px;
			text-align: center;

			strong {
				display: inline-block;
				margin-right: 10px;
				color: $color4;
			}
		}
	}

	.accordion-area {
		margin-top: 30px;
		padding: 20px 0;
		border-top: 1px solid $color-bg2;

		.accordion-ti {
			height: 40px;
			line-height: 20px;
			font-size: 14px;
			font-weight: 700;

			span {
				&::after {
					content: '열기';
					position: relative;
					top: auto;
					right: auto;
					width: auto;
					height: auto;
					transform: none;
					transition: all 0s;
					background: none;
				}
			}

			.icon-arrow-footer {
				margin-top: 3px;
				margin-left: 5px;
				width: 14px;
				height: 14px;
				background-color: $color7;
				border-radius: 50%;

				&::after {
					transform: translate(-50%, -50%) rotate(-180deg);
					transition: all .2s ease-in-out;
				}
			}
			
			&::after {
				display: none;
			}
		}

		.active {
			.accordion-ti {
				span {
					&::after {
						content: '닫기';
					}
				}

				.icon-arrow-footer {
					&::after {
						transform: translate(-50%, -50%) rotate(0);
					}
				}
			}
		}

		.accordion-cont {
			padding: 20px 0 0 0;
			border-bottom: 1px solid $color-bg2;
			>ul {
				position: relative;

				>li {
					&:nth-child(2) {
						position: absolute; 
						padding: 0 0 23px 0;
						top: 0;
						right: 20px;

						>strong {
							margin-bottom: 5px;
						}

						ul {
							li {
								padding: 0 0 8px 0;

								strong {
									font-weight: 400;
								}
							}
						}
					}
				}
			}

			ul {
				display: flex;
				flex-direction: column;
				flex-wrap: nowrap;
			}

			li {
				flex: 1 1 40%;
				padding: 0 140px 23px 0;
				line-height: 17px;
				color: $color5;
				font-size: 12px;

				ul {
					flex-direction: column;

					li {
						padding-left: 0;

						&:nth-child(2n){
							padding-left: 0;
						}
					}
				}
			}

			strong {
				display: block;
				margin-bottom: 2px;
				color: $color4;
				font-weight: 700;
			}

			a {
				display: block;
				padding: 2px 10px 2px 0;
			}
		}
	}

	.util {
		display: flex;
		flex-wrap: wrap;

		a {
			flex: 1 1 40%;
			line-height: 33px;
			color: $color5;
			font-size: 13px;
		}
	}

	.sns {
		display: flex;
		justify-content: space-between;

		a {
			line-height: 60px;
			color: $color4;
			font-size: 13px;
			font-weight: 300;
		}

		.dot {
			position: relative;

			@include dot(2px, 2px, $color6);

			&::before {
				top: 50%;
			}
		}
	}

	.familysite {
		.select {
			.dropdown {
				padding-left: 15px;
				padding-right: 35px;
				height: 32px;
				line-height: 30px;
				font-size: 13px;
				border-color: $color-bg2;

				.current {
					color: $color6;
				}

				.icon-select {
					width: 32px;
					height: 32px;
				}

				.list {
					top: -161px;
					border-color: $color-bg2;
					border-top: 1px solid $color-bg2;
					border-bottom: 0;

					.scrollbar {
						max-height: 160px;
					}

					.option {
						padding: 10px 15px;
						line-height: 12px;
					}
				}
			}
		}
	}

	.cont {
		margin-top: 30px;

		span {
			position: relative;
			padding: 0 8px 0 5px;
			display: inline-block;
			line-height: 18px;
			color: $color6;
			font-size: 13px;

			@include after-line(1px, 11px, 4px 0 0 0);

			a {
				color: $color6;
			}

			small {
				font-size: 13px;
			}

			&:nth-child(1) {
				padding-left: 0;
			}

			&:nth-child(2) {
				&:after {
					display: none;
				}
			}

			&:nth-child(3) {
				display: block;
				margin: 5px 0;
				padding-left: 0;

				&:after {
					display: none;
				}
			}

			&:nth-child(4) {
				padding-left: 0;
			}

			&:nth-child(5) {
				&:after {
					display: none;
				}
			}

			&:nth-child(6),
			&:nth-child(7),
			&:nth-child(8) {
				display: block;
				padding-left: 0;

				&:after {
					display: none;
				}
			}
		}
	}
}

@include tablet {
	#footer {
		.btn-follow {
			.btn-top {
				width: 64px;
				height: 64px;

				&::after {
					transform: translate(-50%, -50%) scale(1.3);
				}
			}

			.btn-qna {
				margin-top: 12px;
				width: 64px;
				height: 64px;

				&::after {
					transform: translate(-50%, -50%) scale(1.3);
				}
			}

			&.active {
				&.fixed {
					top: -180px;
				}
			}

			&.fixed {
				top: -136px;

				&.acitve {
					top: -180px;
				}
			}
		}

		.logo {
			@include position(12px, 0);

			transform: scale(1.09);
			transform-origin: 0 0;
		}

		nav {
			margin-top: 0;
			margin-left: 110px;
			margin-right: 210px;

			a {
				flex: 1 1 30%;
				line-height: 44px;
				font-size: 12px;

				strong {
					font-size: 12px;
				}

				.icon-blank {
					transform: scale(1.09);
					width: 40px;
					height: 44px;
				}
			}
		}

		.qna {
			@include position(5px, auto, 2px);

			width: 190px;

			.btns {
				margin-top: 0;

				.btn {
					height: 48px;
					line-height: 46px;
					font-size: 14px;
					border-radius: 4px;
				}
			}

			p {
				margin-top: 9px;
				margin-left: 5px;
				font-size: 12px;
				text-align: left;
			}
		}

		.accordion-area {
			margin-top: 28px;
			padding-top: 35px;

			.accordion-ti {
				font-size: 14px;

				.icon-arrow-footer {
					transform: scale(1.4);
					margin-left: 15px;
				}
			}

			.accordion-cont {
				padding-top: 42px;
				padding-bottom: 10px;

				>ul {
					position: relative;
	
					>li {
						&:nth-child(2) {
							position: initial; 
							padding-left: 20px;

							>strong {
								margin-bottom: 4px;
							}

							ul {
								li {
									padding: 0 0 12px 0;
								}
							}
						}
					}
				}

				ul {
					display: flex;
					flex-direction: row;
					justify-content: space-between;
					flex-wrap: wrap;
				}

				li {
					flex: initial;
					padding-left: 20px;
					padding-right: 0;
					line-height: 22px;
					font-size: 12px;

					&:nth-child(2n) {
						padding-left: 20px;
					}

					&:nth-child(1) {
						padding-left: 0;
					}

				}
			}
		}

		.util {
			display: block;
			padding-top: 20px;

			a {
				flex: none;
				display: inline-block;
				margin-right: 30px;
				font-size: 12px;
			}
		}

		.sns {
			@include position(auto, auto, 0, -19px);

			width: 250px;

			a {
				font-size: 12px;
			}

			.dot {
				&::before {
					top: calc(50% - 1px);
					width: 3px;
					height: 3px;
				}
			}
		}

		.familysite {
			@include position(auto, auto, 0, 120px);

			width: 200px;

			.select {
				height: 38px;

				.dropdown {
					height: 38px;
					line-height: 36px;
					font-size: 14px;

					.icon-select {
						width: 34px;
						height: 38px;
					}

					.list {
						top: -180px;

						.scrollbar {
							max-height: 180px;
						}
						.option {
							line-height: 16px;
						}
					}
				}
			}
		}

		.cont {
			margin-top: 14px;
			width: 450px;
			line-height: 26px;

			span {
				font-size: 12px;

				&:nth-child(3) {
					display: block;
				}

				&:nth-child(5) {
					&::after {
						display: block;
					}
				}

				&:nth-child(6) {
					display: inline-block;

					&::after {
						display: none;
					}
				}

				&:nth-child(7) {
					display: inline-block;
					padding-left: 0;

					&::after {
						display: block;
					}
				}

				&:nth-child(8) {
					display: inline-block;
					padding-left: 5px;
				}
			}
		}
	}
}

@include desktop-min {
	#footer {
		.btn-follow {
			right: 40px;
			bottom: 60px;

			&.active {
				&.fixed {
					top: -200px;
				}
			}

			&.fixed {
				top: -136px;

				&.acitve {
					top: -200px;
				}
			}
		}
	}
}

@include desktop {
	#footer {
		nav {
			margin-left: 212px;
			margin-right: 300px;

			a {
				font-size: 14px;

				strong {
					font-size: 14px;
				}
			}
		}

		.qna {
			width: 240px;

			.btn {
				font-size: 16px;
			}

			p {
				font-size: 14px;
			}
		}

		.accordion-area {
			.accordion-ti {
				font-size: 16px;
			}

			.accordion-cont {
				li {
					font-size: 14px;
				}
			}
		}

		.util {
			a {
				margin-right: 60px;
				font-size: 14px;
			}
		}

		.sns {
			width: 402px;

			a {
				font-size: 14px;
			}
		}

		.familysite {
			bottom: 60px;

			.select {
				height: 48px;

				.dropdown {
					height: 48px;
					line-height: 46px;
					font-size: 16px;

					.icon-select {
						width: 44px;
						height: 48px;

						&::after {
							width: 30px;
							height: 30px;
						}
					}

					.list {
						top: -240px;

						.scrollbar {
							max-height: 240px;
						}
						.option {
							line-height: 28px;
						}
					}
				}
			}
		}

		.cont {
			width: calc(100% - 402px);
			span {
				font-size: 14px;

				&:nth-child(2) {
					&::after {
						display: block;
					}
				}

				&:nth-child(3) {
					display: inline-block;

					&::after {
						display: block;
					}
				}

				&:nth-child(4) {
					padding-left: 5px;

					&::after {
						display: none;
					}
				}

				&:nth-child(5) {
					padding-left: 0;

					&::after {
						display: block;
					}
				}

				&:nth-child(6) {
					&::after {
						display: block;
					}
				}
			}
		}
	}
}
