/**
* --------------------------------
* Form CSS
* --------------------------------
*/

/* input */
input[type='text'],
input[type='number'],
input[type='password'],
input[type='date'],
input[type='search'],
input[type='email'],
input[type='tel'] {
	padding: 0 12px;
	height: 44px;
	line-height: 42px;
	color: $color4;
	font-size: 14px;
	border: 1px solid $color-bg2;

	&::placeholder {
		color: $color6;
	}

	&:focus {
		border-color: $color4;
	}

	&:disabled {
		color: $color6;
		-webkit-text-fill-color: $color6;
		background: $color-bg1;
		border-color: $color-bg2;
	}

	&:read-only {
		color: $color-bg2;
		-webkit-text-fill-color: $color6;
		background: $color-bg1;
		border-color: $color-bg2;
	}

	&:required {
		background: $color-bg1;
	}

	&:focus {
		border-color: $color4;
	}

	&:read-only:focus {
		border-color: $color-bg2;
	}
}

@include tablet {
	input[type='text'],
	input[type='number'],
	input[type='password'],
	input[type='date'],
	input[type='search'],
	input[type='email'],
	input[type='tel'] {
		padding: 0 rem(22px);
		height: rem(48px);
		line-height: rem(46px);
		font-size: rem(16px);
	}
}

/* textarea */
textarea {
	padding: 10px 12px;
	min-height: 100px;
	line-height: 24px;
	color: $color4;
	font-size: 14px;
	border: 1px solid $color-bg2;
	background-color: transparent;

	&::placeholder {
		color: $color6;
	}

	&:focus {
		border-color: $color4;
	}

	&:read-only {
		background: $color-bg1;
		border-color: $color-bg2;
	}

	&:read-only:focus {
		border-color: $color-bg2;
	}
}

@include tablet {
	textarea {
		padding: rem(15px) rem(22px);
		min-height: rem(150px);
		line-height: rem(28px);
		font-size: rem(16px);
	}
}

/* checkbox & radio */
input[type="radio"],
input[type="checkbox"] {
	@include position-center(1px, 1px, 0, 0);

	width: 18px;
	height: 18px;
	opacity: 0;

	+ span {
		position: relative;
		display: inline-block;
		padding-left: 28px;
		min-height: 20px;
		line-height: 20px;
		color: $color4;
		font-size: 14px;
		user-select: none;
		cursor: pointer;
		vertical-align: top;

		&::before {
			@include background(-30px, -30px, $url: $image-url-form);
			@include position(0, 0);

			content: "";
			display: inline-block;
			width: 20px;
			height: 20px;
			background-color: transparent;
			z-index: 1;
		}
	}

	&:focus {
		+span {
			&::before {
				box-shadow: 0 0 0 1px $color4;
			}
		}
	}
}

input[type="checkbox"] {
	&:checked {
		+span {
			color: $color4;

			&::before {
				background-position: 0 -30px;
			}
		}
	}

	&:disabled {
		+span {
			color: $color-bg2;

			&::before {
				background-position: -30px -30px;
				opacity: .5;
			}
		}
	}

	&:disabled:checked {
		+span {
			&:before {
				background-position: 0 -30px;
				opacity: .5;
			}
		}
	}
}

input[type="radio"] {
	+span {
		&:before {
			background-position: -30px -60px;
		}
	}

	&:checked {
		+span {
			color: $color4;

			&::before {
				background-position: 0 -60px;
			}
		}
	}

	&:disabled {
		+span {
			color: $color-bg2;

			&::before {
				background-position: -30px -60px;
				opacity: .5;
			}
		}
	}

	&:disabled:checked {
		+span {
			&::before {
				background-position: 0 -60px;
				opacity: .5;
			}
		}
	}

	&:focus {
		+span {
			&::before {
				border-radius: 50%;
			}
		}
	}
}

@include tablet {
	input[type="radio"],
	input[type="checkbox"] {
		width: rem(22px);
		height: rem(22px);

		+ span {
			padding-left: rem(34px);
			min-height: rem(24px);
			line-height: rem(24px);
			font-size: rem(18px);

			&::before {
				transform: scale(.8);
				transform-origin: 0 0;
			}
		}
	}
}

@include desktop-min {
	input[type="radio"],
	input[type="checkbox"] {
		+ span {
			&::before {
				transform: scale(1.2);
			}
		}
	}
}

/* select */
.select {
	height: 44px;

	select {
		display: none;
	}

	.dropdown {
		position: relative;
		display: block;
		padding: 0 35px 0 15px;
		width: auto;
		height: 44px;
		line-height: 42px;
		border: 1px solid $color6;
		cursor: pointer;
		color: $color6;
		font-size: 14px;
		outline: none;
		text-align: left;
		transition: all .2s ease-in-out;
		user-select: none;
		white-space: nowrap;

		.icon-select {
			@include position(0, auto, 0);

			width: 42px;
			height: 42px;
			transition: all .125s ease-in-out;
			pointer-events: none;

			&::after {
				width: 30px;
				height: 30px;
			}
		}

		&:focus {
			border-color: $color4;
		}

		&.active {
			.current {
				color: $color5;
			}
		}

		.current {
			@include ellipsis;

			display: block;
			color: $color5;
		}

		.list {
			@include position(100%, -1px, -1px);

			margin-top: 0;
			padding: 0;
			
			background-color: $color7;
			border: 1px solid $color4;
			border-top: 0;
			transition: all 0.15s cubic-bezier(0.25, 0, 0.25, 1.75), opacity .1s linear;
			transform: scale(1);
			transform-origin: 50% 0;
			opacity: 0;
			overflow: hidden;
			pointer-events: none;
			z-index: 20;

			.scrollbar {
				max-height: 200px;

				&.scroll-content {
					margin-bottom: 0 !important;
				}
			}

			.option {
				position: relative;
				padding: 10px 15px;
				line-height: 20px;;
				color: $color5;
				background-color: $color7;
				text-align: left;
				white-space: normal;
				cursor: default;
				outline: none;
				transition: all .2s;

				&:last-child {
					border-bottom: none;
				}

				&:hover,
				&.selected {
					background-color: $color-bg1;
				}

				&.disabled {
					color: $color-bg1;
					cursor: default;
				}

				&.noactive {
					display: none;
				}
			}
		}

		&.open {
			border-color: $color4;

			.icon-select {
				transform: rotate(-180deg);
			}

			.list {
				transform: scale(1);
				opacity: 1;
				pointer-events: auto;
			}

			.option {
				cursor: pointer;
			}
		}
	}

	&.disabled {
		.dropdown {
			color: $color6;
			border-color: $color6;
			background-color: $color-bg1;
			pointer-events: none;

			&::after {
				opacity: .5;
			}

			&:focus {
				border-color: $color6;
			}

			.current {
				color: $color6;
			}

			.list {
				display: none;
			}
		}
	}
}

@include tablet {
	.select {
		height: rem(48px);
	
		.dropdown {
			padding: 0 rem(48px) 0 rem(22px);
			height: rem(48px);
			line-height: rem(46px);
			font-size: rem(16px);

			.icon-select {
				@include position(0, auto, 0);

				width: rem(46px);
				height: rem(46px);

				&::after {
					transform: translate(-50%, -50%) scale(.8);
				}
			}

			.list {
				.scrollbar {
					max-height: rem(220px);
				}

				.option {
					position: relative;
					padding: rem(10px) rem(22px);
					line-height: rem(24px);
				}
			}
		}
	}
}

@include desktop-min {
	.select {
		.dropdown {
			padding-right: rem(57px);

			.icon-select {
				width: rem(60px);

				&::after {
					transform: translate(-50%, -50%) scale(1.4);
				}
			}
		}
	}
}

/* form-entry */
.form-entry {
	position: relative;
	display: block;
	margin-top: 12px;
	font-size: 0;
	text-align: left;

	&:first-of-type {
		margin-top: 0;
	}

	> strong {
		display: block;
		line-height: 32px;
		color: $color4;
		font-size: 14px;
		font-weight: 400;

		.essential {
			margin-left: 2px;
			display: inline-block;
			font-style: normal;
			color: $color3;
		}
	}

	>input {
		position: relative;
		background-color: transparent;
		z-index: 1;
	}

	>.select {
		+.select {
			margin-top: 10px;
		}
	}
}

.input-info {
	position: relative;
	margin-top: 5px;
	padding-left: 7px;
	line-height: 19px;
	color: $color6;
	font-size: 13px;
	text-align: left;

	@include dot(2px, 2px, $color6, 8px 0 0 0);
}

@include tablet {
	.form-entry {
		margin-top: rem(20px);

		> strong {
			line-height: rem(39px);
			font-size: rem(16px);
			font-weight: 400;

			.essential {
				margin-left: rem(4px);
			}
		}

		>.select {
			+.select {
				margin-top: rem(17px);
			}
		}
	}

	.input-info {
		margin-top: rem(10px);
		line-height: rem(25px);
		font-size: rem(15px);

		&::before {
			margin-top: 10px;
			width: 3px;
			height: 3px;
		}
	}
}

/* form-write */
.form-write {
	input[type='file'] {
		display: none;
	}

	.upfilename {
		width: calc(100% - 110px);

		&:read-only,
		&:read-only:focus {
			color: $color4;
			background-color: transparent;
			-webkit-text-fill-color: $color4;
		}
	}

	.btn {
		margin-left: 10px;
		width: 100px;
		color: $color4;
		font-weight: 400;
		border-color: $color-bg2;
		cursor: pointer;
	}
}

/* form-agree */
.form-agree {
	.btn-line {
		@include position(0, auto, 0);
	}
}

@include desktop-min {
	.form-write {
		.upfilename {
			width: calc(100% - 160px);
		}

		.btn {
			margin-left: 12px;
			width: 148px;
		}
	}
}
