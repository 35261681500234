@charset "utf-8";

@import url(lib/normalize.min.css);

@import
	"./abstracts/variables",
	"./abstracts/functions",
	"./abstracts/mixins";

@import
	"./base/init",
	"./base/fonts";

@import "components/_accordion.scss";
@import "components/_button.scss";
@import "components/_form.scss";
@import "components/_icons.scss";
@import "components/_keyframes.scss";
@import "components/_popup.scss";
@import "components/_tabs.scss";

@import
	"./base/layout",
	"./base/header",
	"./base/footer",
	"./base/contents";


// -----------------------------------------------------------------------------
// COMMON
// -----------------------------------------------------------------------------

@include mobile {
	[data-respond='mobile'] {
		display: block;
	}
	[data-respond='pc'] {
		display: none;
	}

	html, body {
		font-size: 12px;
	}
}

@include tablet {
	[data-respond='mobile'] {
		display: none;
	}
	[data-respond='pc'] {
		display: block;
	}

	html, body {
		font-size: 12px;
	}
}

@include desktop-min {
	html, body {
		font-size: 14px;
	}
}

@include desktop {
	html, body {
		font-size: 16px;
	}
}

/* skip */
.skip {
	position: absolute;
	display: flex;
	top: -50px;
	left: 0;
	padding: 10px;
	width: 100%;
	color: $color4;
	text-align: center;
	text-decoration: none;
	background-color: rgba(255, 255, 255, .8);
	z-index: 9999;

	&:focus {
		top: 0;
	}
}
