// -----------------------------------------------------------------------------
// INIT
// -----------------------------------------------------------------------------

html {
	width: 100%;
}

body {
	position: relative;
	margin: 0;
	width: 100%;
	line-height: $line-height;
	color: $color4;
	font-size: $font-size;
	font-weight: $font-weight;
	font-family: $font-family1, 'Malgun Gothic', '맑은고딕', Dotum, '돋움', sans-serif;
	letter-spacing: $letter-spacing;
	word-break: keep-all;
	word-wrap: break-word;
	-webkit-text-size-adjust: none;
	-webkit-overflow-scrolling: touch;
}

a {
	color: $color5;
	text-decoration: none;
	outline: 0;

	@include on-event {
		color: $color4;
	}
}

button {
	padding: 0;
	border: 0;
	font-family: $font-family1;
	cursor: pointer;
	background-color: transparent;
	outline: 0;
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
	-webkit-tap-highlight-color: transparent;
}

*,
*::before,
*::after {
	box-sizing: border-box;
}

.clear::after {
	clear: both;
	content: '';
	display: table;
}

.hide {
	overflow: hidden;
	padding: 0;
	text-indent: 101%;
	white-space: nowrap;
}

.hidden {
	position: absolute;
	display: block;
	margin: 0 !important;
	padding: 0 !important;
	width: 0px !important;
	height: 0px !important;
	border: 0 !important;
	clip: rect(0 0 0 0) !important;
	overflow: hidden !important;
}

h1,
h2,
h3,
h4,
h5,
h6,
i,
p,
div,
span,
li {
	margin: 0;
}

ul,
ol {
	list-style: none;
	margin: 0;
	padding: 0;
}

fieldset {
	margin: 0;
	padding: 0;
	border: none;
}

label {
	position: relative;
}

textarea {
	width: 100%;
	resize: none;
	overflow-y: auto;
	-webkit-appearance: none;
}

input,
select,
textarea {
	font-family: $font-family1;
	padding: 0;
	border-radius: 0;
}

input[type='text'],
input[type='number'],
input[type='password'],
input[type='date'],
input[type='search'],
input[type='email'],
input[type='tel'] {
	width: 100%;
	-webkit-appearance: none;
}

input[type='text']::-ms-clear,
input[type='password']::-ms-clear,
input[type='password']::-ms-reveal {
	display: none;
}

input[type='number'] {
	-moz-appearance: textfield;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
	-webkit-appearance: none;
}

input[type='file'] {
	cursor: pointer;
}

input[type="text"]:focus,
input[type="number"]:focus,
input[type="password"]:focus,
input[type="date"]:focus,
input[type="search"]:focus,
input[type="email"]:focus,
input[type="tel"]:focus,
textarea:focus,
select:focus {
	outline: 0;
}

em {
	display: inline-block;
	font-style: normal;
}

strong {
	font-weight: 700;
}

table {
	table-layout: fixed;
	width: 100%;
	border-collapse: collapse;
	border-spacing: 0;
}

table caption {
	position: absolute;
	margin: -1px;
	padding: 0;
	width: 1px;
	height: 1px;
	border: 0;
	clip: rect(0 0 0 0);
	overflow: hidden;
}

th,
td {
	padding: 0;
}

dl,
dd {
	margin: 0;
}

figure {
	margin: 0;
}

img {
	max-width: 100%;
	vertical-align: top;
}

img[usemap],
map area {
	outline: 0;
}

hr {
	display: none;
}

address {
	font-style: normal;
}
