/**
* --------------------------------
* Icons CSS
* --------------------------------
*/

@include background-icon('blank', 0, -30px, 30px, 35px);
@include background-icon('arrow-footer', 0, 0);
@include background-icon('select', 0, 0, $url: $image-url+'common/form.png');
@include background-icon('corporate', -120px, -30px);
@include background-icon('management', -150px, -30px);
@include background-icon('global', -180px, -30px);
@include background-icon('university', -210px, -30px);
@include background-icon('public', -240px, -30px);
@include background-icon('reemployment', -270px, -30px);
@include background-icon('more', -120px, 0);

